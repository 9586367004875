import React from 'react';

import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">

        <p>
          Em breve um novo conceito em equipamentos e produtos para fisioterapia.
        </p>
        <p>
          fisioloja.com.br
        </p>

      </header>
    </div>
  );
}

export default App;
